<template>
  <div>
    <h3 class="empty-ph is-size-6 text-secondary mt-3 mb-3">
      {{this.mainTitleStart}} <dropdown
      id="dailyPayoutSelector1"
      :currency="currency"
      :possible-values="possibleDailyPayoutValues"
      :default-value="dailyPayout"
      selection-color="var(--secondary)"
      @selected="updateDailyPayout"
    /> {{this.mainTitleEnd}}
    </h3>
    <div v-if="noDaysUntilStart <= 31">
      <hr />
      <p class="empty-ph">
        {{ this.urgency_to_buy_text_formatted }}
      </p>
    </div>
    <hr />
    <div>
      <h4 class="empty-ph is-size-6"
      style="display: inline-block"
      >
        {{this.subTitle}}
      </h4>
      <share-price-offer
        :share-text="shareText"
        :whatsapp-share-text="whatsappShareText"
        :email-share-text="emailShareText"
        :email-subject-text="emailSubjectText"
        :copy-to-clipboard-text="copyToClipboardText"
        :street-and-number="streetAndNumber"
        :zip-code="zipCode"
        :town="town"
        :country="country"
        :start-date="startDateNum"
        :end-date="endDateNum"
        :daily-payout="dailyPayout"
        :partner-code="partnerCode"
        :lang="this.lang"
        style="float: right;"
      />
    </div>
    <p class="empty-ph">
      <b>{{ this.maxPayoutTextFormatted }}</b>
    </p>
    <p class="empty-ph">
      <b>{{ this.periodTitle }}</b>
      <br/>{{ this.periodTextFormatted }}
    </p>
    <p class="empty-ph">
      <b>{{ this.locationTitle }}</b>
      <br/>{{ this.locationTextFormatted }}
    </p>
    <p class="empty-ph" style="margin-bottom: 0">
      <b>{{ this.severityTitle }}</b>
      <br/>{{ this.severityTextFormatted }}
    </p>
    <rollout-text
      :header-text="rainAmountHeader"
      :html-text="rainAmountInfo"
      selection-color="var(--secondary)"
      style="margin-bottom: 20px"
      id="threshold-rollout"
      @rollout-opened="logOpened"
    ></rollout-text>
    <p v-if="this.riskDays > 0" class="empty-ph">
      <b>{{ this.nonInsuredDaysTitleFormatted }}</b>
      <br/>{{ this.nonInsuredDaysTextFormatted }}
    </p>
  </div>
</template>

<script>
import FormatService from '@/services/FormatService';
import dropdown from '@/components/ui/dropdownUi.vue';
import LogService from '@/services/LogService';
import numbersInWords from '@/enums/numbersInWords';
import rolloutText from '@/components/ui/rolloutText.vue';
import sharePriceOffer from '@/components/buyVacation/sharePriceOffer.vue';

export default {
  components: { dropdown, rolloutText, sharePriceOffer },
  props: {
    possibleDailyPayoutValues: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    mainTitleStart: {
      type: String,
      required: true,
    },
    mainTitleEnd: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    periodTitle: {
      type: String,
      required: true,
    },
    periodText: {
      type: String,
      required: true,
    },
    locationTitle: {
      type: String,
      required: true,
    },
    severityTitle: {
      type: String,
      required: true,
    },
    severityText: {
      type: String,
      required: true,
    },
    nonInsuredDaysTitle: {
      type: String,
      required: true,
    },
    maxPayoutText: {
      type: String,
      required: true,
    },
    nonInsuredDaysText: {
      type: String,
      required: true,
    },
    priceTitle: {
      type: String,
      require: true,
    },
    initialDailyPayout: {
      type: Number,
      required: true,
    },
    riskDays: {
      type: Number,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    startDateNum: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    endDateNum: {
      type: String,
    },
    currency: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    streetAndNumber: {
      type: String,
    },
    zipCode: {
      type: String,
    },
    town: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    partnerCode: {
      type: String,
      required: true,
    },
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    threshold: {
      type: Number,
      required: true,
    },
    minHour: {
      type: Number,
      required: true,
    },
    maxHour: {
      type: Number,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    maxPayout: {
      type: Number,
      required: true,
    },
    hotelName: {
      type: String,
      required: true,
    },
    weatherContextDerived: {
      type: String,
    },
    rainy_day_str: {
      type: String,
      required: true,
    },
    rainy_days_str: {
      type: String,
      required: true,
    },
    is_str: {
      type: String,
      required: true,
    },
    are_str: {
      type: String,
      required: true,
    },
    today: {
      type: String,
      required: true,
    },
    tomorrow: {
      type: String,
      required: true,
    },
    urgency_to_buy_text: {
      type: String,
      required: true,
    },
    until: {
      type: String,
      required: true,
    },
    min_postpone: {
      type: Number,
      required: true,
    },
    rainAmountHeader: {
      type: String,
      default: undefined,
    },
    rainAmountInfo: {
      type: String,
      default: undefined,
    },
    shareText: {
      type: String,
      required: true,
    },
    whatsappShareText: {
      type: String,
      required: true,
    },
    emailShareText: {
      type: String,
      required: true,
    },
    emailSubjectText: {
      type: String,
      required: true,
    },
    copyToClipboardText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dailyPayout: this.initialDailyPayout,
    };
  },
  methods: {
    logOpened() {
      this.$emit('threshold-rollout-opened');
    },
    updateDailyPayout(newValue) {
      LogService.log('mainSection daily payout updated', newValue);
      this.dailyPayout = newValue;
      this.$emit('update-daily-payout', newValue);
    },
    getNumberAsWord(number) {
      const ind = this.lang === 'de' && number === 1 ? 'ein' : number.toString();
      if (Object.keys(numbersInWords).includes(this.lang)
        && Object.keys(numbersInWords[this.lang]).includes(ind)) {
        return numbersInWords[this.lang][ind];
      }
      return number;
    },
  },
  watch: {
    initialDailyPayout(newValue) {
      this.dailyPayout = newValue;
    },
  },
  computed: {
    urgency_to_buy_text_formatted() {
      if (this.noDaysUntilStart === this.min_postpone) {
        return FormatService.format(
          this.urgency_to_buy_text,
          this.noDaysUntilStart,
          this.today,
          this.min_postpone,
        );
      }
      if (this.noDaysUntilStart === (this.min_postpone + 1)) {
        return FormatService.format(
          this.urgency_to_buy_text,
          this.noDaysUntilStart,
          this.tomorrow,
          this.min_postpone,
        );
      }
      const daysUntilLastDay = this.noDaysUntilStart - this.min_postpone;
      const lastDayDate = this.$moment.today().add(daysUntilLastDay, 'days').format('LL');
      return FormatService.format(
        this.urgency_to_buy_text,
        this.noDaysUntilStart,
        `${this.until} ${lastDayDate}`,
        this.min_postpone,
      );
    },
    noDaysUntilStart() {
      return Math.ceil((Date.parse(this.startDateNum) - new Date()) / (1000 * 60 * 60 * 24));
    },
    maxPayoutTextFormatted() {
      return FormatService.format(this.maxPayoutText, this.maxPayout, this.currency);
    },
    periodTextFormatted() {
      return this.startDate === this.endDate
        ? this.startDate
        : FormatService.format(this.periodText, this.startDate, this.endDate);
    },
    locationTextFormatted() {
      if (this.hotelName !== '') {
        return `${this.hotelName}, ${this.address} (GPS ${this.latitude}, ${this.longitude})`;
      }

      return `${this.address} (GPS ${this.latitude}, ${this.longitude})`;
    },
    severityTextFormatted() {
      return FormatService.format(
        this.severityText,
        Intl.NumberFormat(this.lang).format(this.threshold),
        this.minHour,
        this.maxHour,
        this.weatherContextDerived,
      );
    },
    nonInsuredDaysTitleFormatted() {
      return FormatService.format(
        this.nonInsuredDaysTitle,
        this.getNumberAsWord(this.riskDays),
        this.riskDays === 1 ? this.rainy_day_str : this.rainy_days_str,
        this.riskDays === 1 ? this.is_str : this.are_str,
      );
    },
    nonInsuredDaysTextFormatted() {
      return FormatService.format(
        this.nonInsuredDaysText,
        this.getNumberAsWord(this.riskDays + 1),
      );
    },
    priceFormatted() {
      return `${new Intl.NumberFormat(this.lang, { minimumFractionDigits: 2 })
        .format(this.price)} ${this.currency}`;
    },
    priceTitleFormatted() {
      return `${this.priceTitle} ${this.priceFormatted}`;
    },
  },
};
</script>
