<template>
<div>
  <span class="dd-main" :class="{
        open: isOpen,
      }" :id="id"  style="display: inline-block">
    <span>
      <img src="@/assets/images/svg/share.svg" width="12px"
           style="margin-right: 5px; margin-bottom: 4px"/>
      <span @click="isOpen = !isOpen">
        <a class="dd-selection">
          {{ shareText }}
        </a>
      </span>
    </span>
    <div v-if="isOpen" class="dd-list">
      <div class="dd-entries">
        <a :href="hrefWhatsapp()" target="_blank" @click="closeShareQuoteWhatsapp()">
          {{this.whatsappShareText}}
        </a>
      </div>
      <div class="dd-entries">
        <a :href="emailLink()" target="_blank" @click="closeShareQuoteEMail()">
          {{this.emailShareText}}
        </a>
      </div>
      <div class="dd-entries" @click="copyToClipboard()">
        {{this.copyToClipboardText}}
      </div>
    </div>
  </span>
</div>
</template>

<script>

import LogService from '@/services/LogService';
import configEnv from '@/enums/configEnv';
import deepLinkSource from '@/enums/deepLinkSource';
import { eventNames } from '@/enums/googleTagManager';
import Integer from 'vuelidate/lib/validators/integer';

export default {
  props: {
    id: {
      type: String,
      default: 'selector-share-price-offer',
    },
    shareText: {
      type: String,
      required: true,
    },
    whatsappShareText: {
      type: String,
      required: true,
    },
    emailShareText: {
      type: String,
      required: true,
    },
    emailSubjectText: {
      type: String,
      required: true,
    },
    copyToClipboardText: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
    },
    streetAndNumber: {
      type: String,
    },
    town: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    zipCode: {
      type: String,
    },
    partnerCode: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    dailyPayout: {
      type: Integer,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      possibleValues: [this.whatsappShareText, this.emailShareText, this.copyToClipboardText],
    };
  },
  methods: {
    hrefWhatsapp() {
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(
        this.offerLink(deepLinkSource.WHATSAPP),
      )}`;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.offerLink(deepLinkSource.CLIPBOARD));
      this.closeShareQuoteSelection(deepLinkSource.CLIPBOARD);
    },
    closeShareQuoteWhatsapp() {
      this.closeShareQuoteSelection(deepLinkSource.WHATSAPP);
    },
    closeShareQuoteEMail() {
      this.closeShareQuoteSelection(deepLinkSource.EMAIL);
    },
    closeShareQuoteSelection(event) {
      this.$webAppAPI.postUpdateVacationField(eventNames.quoteLinkCopied, event);
      window.dataLayer.push({ event: eventNames.quoteLinkCopied });
      this.isOpen = false;
    },
    select(selected, index) {
      LogService.log('sharePriceOffer, selected', selected, index);
      this.isOpen = false;
    },
    emailLink() {
      const body = encodeURIComponent(this.offerLink(deepLinkSource.EMAIL));
      return `mailto:?subject=${this.emailSubjectText}&body=${body}`;
    },
    offerLink(source) {
      LogService.log('sharePriceOffer', this.lang);
      let params = `partner_code=${this.partnerCode}&hotel_town=${this.town}&hotel_country=${this.country}&start_date=${this.startDate}&end_date=${this.endDate}&daily_payout=${this.dailyPayout}&create_session=true&cities_allowed=true&source=${source}`;
      if (this.streetAndNumber) {
        const streetString = encodeURIComponent(this.streetAndNumber);
        params = `${params}&hotel_street=${streetString}`;
      }
      if (this.zipCode) {
        params = `${params}&hotel_zip=${this.zipCode}`;
      }
      const langUrl = this.lang === 'en' ? '' : `/${this.lang}`;
      return `${configEnv.own_url}${langUrl}/create-new-vacation-contract?${params}`;
    },
  },
};
</script>

<style>
.dd-list {
  position: absolute;
  top: 33px;
  left: -31px;
  background-color: white;
  min-width: 140px;
  border: 1px solid var(--border-input-color);
}
.dd-main {
  cursor: pointer;
  position: relative;
  padding: 2px;
  min-width: 110px;
  border: 1px solid var(--border-input-color);
}
.dd-main:hover, .dd-main.open {
  box-shadow: 0 5px .5rem rgba(0, 0, 0, 0.2);
}
.dd-entries {
  cursor: pointer;
  padding: 5px 10px;
}

.dd-entries:hover {
  background-color: #f6f6f6;
  box-shadow: 0 5px .5rem rgba(0, 0, 0, 0.2);
}
</style>
