<template>
  <div>
    <span class="dd-main" :class="{
          open: isOpen,
        }" :id="id">
      <span @click="openRollout">
      <info-box
        :color="selectionColor"
        :width="12"
        style="margin: auto 5px 2px 0;"
      />
        <a class="dd-selection" :style="{color: selectionColor}" style="font-size: 14px">
          {{headerText}}
        </a>
      <openArrow
        :is-open="isOpen"
        :color="selectionColor"
        :width="arrowSeize"
        :margin-left="5"
      />
      </span>
    </span>
    <div v-if="isOpen" class="info-box" v-html="htmlText" style="margin-top: 10px"></div>
  </div>

</template>

<script>
import openArrow from './openArrow.vue';
import infoBox from './infoBox.vue';

export default {
  props: {
    id: {
      type: String,
      default: 'rolloutText',
    },
    selectionColor: {
      type: String,
      required: true,
    },
    arrowSeize: {
      type: Number,
      default: 10,
    },
    headerText: {
      type: String,
      default: '',
    },
    htmlText: {
      trype: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openRollout() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit('rollout-opened');
      }
    },
  },
  components: {
    openArrow,
    infoBox,
  },
};
</script>
<style scoped>
.info-box {
  padding: 10px;
  border-style: solid;
  border-color: var(--secondary);
  border-radius: var(--border-radius);
}
.dd-main:hover {
  cursor: pointer;
}
</style>
