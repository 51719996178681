<template>
  <!-- Uploaded to: SVG Repo, www.svgrepo.com, Transformed by: SVG Repo Mixer Tools -->
  <svg class="svg"
       :style="{'--color_fill': color}" :width="`${width}px`"  :height="`${width}px`">
  >
    <use href="@/assets/images/svg/infobox.svg#img"></use>
  </svg>
</template>
<script>
import Integer from 'vuelidate/lib/validators/integer';

export default {
  props: {
    width: {
      type: Integer,
      default: 15,
    },
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>
